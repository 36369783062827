@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

$colors: (
    background: #EEE,
    text: #111
);

$font: (
    family: 'Major Mono Display',
    size: 6vw,
    letter-spacing: 0
);

$config: (
    animated: false,
    nb-letters: 29,
    animation-length: 9s,
    mask-letters: ("A","B","C","Č","Ć","D","Đ","E","F","G","H","I",
    "J","K","L","M","N","O","P","Q","R","S","Š","T","U","V","W","X","Y",
    "Z","Ž","a","b","c","č","ć","d","đ","e","f","g","h","i","j","k","l",
    "m","n","o","p","q","r","s","š","t","u","v","w","x","y","z","ž","Ă",
    "Â","Ê","Ô","Ơ","Ư","ă","â","ê","ô","ơ","ư","1","2","3","4",
    "5","6","7","8","9","0","\?","\!","\@","\&","\$","\€","\£","\¥","\¢")
);

// ----------------------------------

@function color($value) {
    @return map-get($colors, $value);
}

@function font($value) {
    @return map-get($font, $value);
}

@function config($value) {
    @return map-get($config, $value);
}

@function random-string($list) {
    $result: null;
    @for $i from 1 through length($list) {
        $random: random(length($list));
        $current: nth($list, $random);
        $list: remove-nth($list, $random);
        $result: $result#{$current};
    }
    @return $result;
}

@function remove-nth($list, $index) {
    $result: ();
    @for $i from 1 through length($list) {
        @if $i != $index {
            $result: append($result, nth($list, $i));
        }
    }
    @return $result;
}

@mixin respond-to($breakpoint) {
    @if type-of($breakpoint) == list {
        @media (min-width: nth($breakpoint, 1)) and (max-width: nth($breakpoint, 2)) {
            @content;
        }
    }
    @else {
        @media (max-width: $breakpoint) {
            @content;
        }
    }
}

// ----------------------------------



.wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 80vw;
    font-size: 0;
    transform: translate(-50%);
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
 }

.letter {
    width: 5vw;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    margin: 0 ceil(font(letter-spacing) / 2);
    font-family: font(family);
    font-size: font(size);
    font-weight: 1000;
    line-height: font(size);
    text-transform: uppercase;
    color: color(text);
    
    @include respond-to(767px) {
        font-size: font(size / 2)
    }
    &:before {
        position: absolute;
        top: 20px;
        left: 0;
        width: 110%;
        word-break: break-all;
        background-color: color(background);
    }
}

.letters{
    text-align: center;
}

// ----------------------------------

@for $i from 1 through config(nb-letters) {
    $length: length(config(mask-letters));
    $random: random($length);
    $steps: $random - 1;
    $offset: font(size) * $steps * -1;
    $delay: random(100) / 100;
      $duration: $steps * (config(animation-length) / $random);
    .letter:nth-child(#{$i}):before {
        content: quote(random-string(config(mask-letters)));
        margin-top: $offset;
        animation-name: letter#{$i};
        animation-duration: $duration - ($duration * $delay);
        animation-delay: $delay * 1s;
        animation-fill-mode: forwards;
    }
    @keyframes letter#{$i} {
        from {
            margin-top: $offset;
        }
        to {
            margin-top: font(size);
        }
    }
}