
.contactPage {
	height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
	overflow-y:hidden;
	overflow-x:hidden;
	font-family: 'Major Mono Display';
	text-transform: lowercase;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
}

#copyBtn {
	display: none;
}

.copy {
	margin-top: 10vh;
}

.resume {
	margin-top: 10vh;
}

.btnFlex {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2vw;

}

.btnsWrap {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	
}


.contactBtn {
	padding:2vw;
	text-decoration: none;
	color: black;
	transition: all 0.3s;
	position: relative;
	font-family: inherit;
	font-size: large;
	outline:none;
	text-transform: lowercase;
	white-space: nowrap;
	background-color: transparent;
	border: none;
	cursor:pointer;
	font-size: 2.5vh;
  }
  
  
  .contactBtn::before {
	content: ' ';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border-top-width: 3px;
	border-bottom-width: 3px;
	border-top-style: solid;
	border-bottom-style: solid;
	border-top-color: rgba(0,0,0,0.5);
	border-bottom-color: rgba(0,0,0,0.5);
	transform: scale(0.3, 1.1);
	
  }
  
  .contactBtn:hover::before {
	opacity: 1;	
	transform: scale(1, 1);	
  }
  .contactBtn::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: all 0.3s;
	background-color: transparent;
  
  }
  .contactBtn:hover::after {
	opacity: 0;	
	transform: scale(0.1, 1);

  }



  /* scrolling letters styles in scss file */

  @media (max-width: 768px) {

	.greeting {
		color:white;
		font-size: 6vh;
		white-space:nowrap;
		padding: 1vh;
		font-weight:bolder;
		text-transform: uppercase;
	}
	
	.btnsWrap {
		flex-direction: column;
		padding: 1vh;
		align-items: center;
		font-weight: bold;
		margin:1vh;
		max-width:100%
	}

	.btnFlex {
		max-width: 90vw;
		max-height: 90vh;
		background:linear-gradient( to bottom, transparent 0%, rgba(163, 142, 139, 0.7)100%);
		border-radius: 25px
	}

	.pdf, .copy, .send, .download{
		font-family: 'Major Mono Display';
		text-transform: lowercase;
		border:none;
		outline:none;
		background: rgba(179,138,132, 0.5);
		font-size: 4vh;
		text-decoration:none;
		text-align:center;
		color: white;
		font-weight: 900;
		margin: 2vh;
		border-top-width: 3px;
		border-bottom-width: 3px;
		border-top-style: solid;
		border-bottom-style: solid;
		border-top-color: rgba(255,255,255,0.5);
		border-bottom-color: rgba(255,255,255,0.5);
		padding: 1.5vh;
		border-radius: 10px
	};

  }