@import url('https://fonts.googleapis.com/css?family=Inknut+Antiqua&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
  background-color: black;
}

body{
  margin: 0;
}


